<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      :confirmLoading="confirmLoading"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      cancelText="关闭">
     <a-form  :form="form" :label-col="{ span: 17 }" :wrapper-col="{ span: 7}">
          <a-row>
            <a-col :span="24">
              <a-form-item label="设置销量" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number :max="999999" :min="0" :precision="0"
                  v-decorator="['saleCount', { rules: [{ required: true, message: '设置销量(会覆盖原销量数据)!' }] , initialValue:'0'}]"
                  placeholder="设置销量(会覆盖原销量数据)" style="width: 100%" />
              </a-form-item>
            </a-col>
          </a-row>
     </a-form>
    </a-modal>

</template>

<script>

  import { httpAction, postAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'

  // 添加商品规格
  export default {
    name: 'AddShopSpecsForm',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        title:'修改销量',
        width:600,
        visible: false,
        openLanguage:false,
        fileList: [],
        goodsDetailFileList:[],
 
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        shopGroupFormList:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        record:{},
        url: {
          add: "",
          edit: "",
          updateSaleCount:'/shop/web/shop/updateSaleCount'
        }
      }
    },
    computed: {
      
    },
    created () {
    
    },
    methods: {
      show (param) {
        this.$nextTick(() => {
            setTimeout(() => {
              this.form.setFieldsValue({
                saleCount: param.saleCount,
              })
            })
        });
        this.title = "修改销量: " + param.goodsNameZh
        this.record = param;
        this.visible = true;
      },
      edit (record) {
        this.visible = true;
      },
      submitForm () {
        this.form.validateFields((err, values) => {
          if (!err) {
              let param = {
                saleCount: values.saleCount,
                goodsId: this.record.id
              }
              this.updateSaleCount(param);
          }
         })
      },
      updateSaleCount(param){
        let that = this
        this.confirmLoading = true
        postAction(this.url.updateSaleCount,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("修改成功");
            that.close()
            that.$emit('ok',res.data);
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      beforeUpload(file) {
        this.fileList = [];
        this.fileList = [...this.fileList, file];
        return false;
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      goodsDetailBeforeUpload(file){
        this.goodsDetailFileList = [...this.goodsDetailFileList, file];
        return false;
      },
      goodsDetailHandleRemove(file) {
        const index = this.goodsDetailFileList.indexOf(file);
        const newFileList = this.goodsDetailFileList.slice();
        newFileList.splice(index, 1);
        this.goodsDetailFileList = newFileList;
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      close () {
        this.visible = false;
      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>