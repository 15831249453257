<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="优惠券金额(整数)">
                  <a-input-number :max="99999" :min="0" :precision="0"
                           v-decorator="['couponMoney', { rules: [{ required: true, message: '请输入优惠券金额,必须是整数!' }] }]"
                           placeholder="请输入优惠券金额,必须是整数" style="width: 100%" />        
                    <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />每次设置都会覆盖之前设置的优惠券,如果想下架优惠券,请设置0        
                  </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="触发时机">
                  <a-radio-group  v-decorator="['couponHappen', { rules: [{ required: true, message: '请选择触发时机！' }] }]" >
                      <a-radio :value="1">
                        进入页面触发
                      </a-radio>
                      <a-radio :value="2">
                        离开页面触发
                      </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
    </a-modal>  

</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/commonUtil'
  export default {
    name: 'ShopCouponModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        openLanguage:false,
        confirmLoading: false,
        record:{},
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          add: "",
          edit: "",
          setShopCoupon:'/shop/web/shop/setShopCoupon'
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      show (record) {
        console.log(record)
        this.form.resetFields();
        this.record = record;
        this.title = "设置优惠券: " + record.goodsNameZh
        this.$nextTick(() => {
            setTimeout(() => {
              this.form.setFieldsValue({
                couponMoney: record.couponMoney,
                couponHappen: record.couponHappen,
              })
            })
        });
        this.visible = true;
      },
      edit (record) {
        this.visible = true;
        this.title = "新增分组"
      },
      submitForm () {
        let that = this;
        this.form.validateFields((err, values) => {
          if (!err) {
                let param = {
                  goodsId : that.record.id,
                  couponMoney: values.couponMoney,
                  couponHappen:  values.couponHappen
                }
              that.confirmLoading = true
              postAction(this.url.setShopCoupon,param).then((res)=>{
                if(res.code == 200){
                  that.$message.success("设置成功");
                  that.handleCancel()
                  that.$emit('ok');
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.confirmLoading = false;
              })
          }
         })
      },
      handleCancel () {
        this.visible = false;
      }
    }
  }
</script>