<template>
    <a-modal
        :title="title"
        :width="width"
        :visible="visible"
        switchFullscreen
        @ok="submitForm"
        @cancel="handleCancel"
        :confirmLoading="confirmLoading"
        cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="商品名称">
                  <a-select show-search
                        placeholder="请选择商品"
                        option-filter-prop="children"
                        style="width: 100%"
                        v-decorator="['goodsId', { rules: [{ required: true, message: '请选择商品!' }] }]"
                        :filter-option="filterOption"
                      >
                        <a-select-option :value="item.id" v-for="(item, i) in shopList" :key="i">
                          {{item.goodsNameZh}}
                        </a-select-option>
                      </a-select>
                 </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="评论内容">
                  <a-input :maxLength="200" v-decorator="['content', { rules: [{ required: true, message: '请输入评论内容!' }] }]"  placeholder="请输入评论内容"  ></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="评论图" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-upload
                        v-decorator="['contentFile', { rules: [{ required: false, message: '请选择评论图!' }] }]"
                        :file-list="contentFile"
                        :before-upload="contentBeforeUpload"
                        accept=".png,.jpg"
                        :remove="contentHandleRemove"
                      >
                    <div v-if="contentFile.length < 4">
                        <a-button type="primary"> <a-icon type="upload" />上传评论图</a-button>
                        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 评论图最多上传4个
                    </div>
                </a-upload>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="用户头像" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-upload
                        v-decorator="['avatarFile', { rules: [{ required: true, message: '请选择用户头像!' }] }]"
                        :file-list="avatarFile"
                        :before-upload="avatarBeforeUpload"
                        accept=".png,.jpg"
                        :remove="avatarHandleRemove"
                      >
                    <div v-if="avatarFile.length < 1">
                        <a-button type="primary"> <a-icon type="upload" />上传用户头像</a-button>
                        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 用户头像最多上传1个
                    </div>
                </a-upload>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="是否发布">
                  <a-checkbox :defaultChecked="true" v-decorator="['sendEvaluate', { rules: [{ required: false, message: '!' }]  , initialValue:true }]"   >
                  </a-checkbox>
                </a-form-item>
              </a-col>
          </a-row>
          </a-form>
    </a-modal>  

</template>

<script>

  import { postAction, getAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'
  export default {
    name: 'RotationFormModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        openLanguage:false,
        contentFile:[],
        avatarFile:[],
        shopList:[],
        confirmLoading: false,
        record:{},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          add: "",
          edit: "",
          createEvaluate: "/shop/web/shop/createEvaluate"
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      add (shopList) {
        this.shopList = shopList;
        this.visible = true;
        this.title = "新增评论";
        this.contentFile=[];
        this.avatarFile =[];
        this.form.resetFields();
      },
      edit (record) {
        this.visible = true;
        this.record = record;
        this.title = "新增分组"
      },
      submitForm () {
        this.form.validateFields((err, values) => {
          if (!err) {
            const formData = new FormData();
             for(let i in values){
               let v = values[i]
               if(!isEmpty(v)){
                  if(v.fileList){
                    v.fileList.forEach(file => {
                        if(file.originFileObj){
                            formData.append(i, file.originFileObj);
                        }else{
                            formData.append(i, file);
                        }
                      });
                  }else if(v.file){
                     formData.append(i, v.file);
                  }else{
                    formData.append(i, v);
                  }
               }
             }
             this.createEvaluate(formData)
          }
         })
      },
      createEvaluate(formData){
        let that = this
        this.confirmLoading = true
        uploadAction(this.url.createEvaluate,formData).then((res)=>{
          if(res.code == 200){
            that.$message.success("创建商品成功");
            that.$emit('ok');
            that.handleCancel()
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      handleCancel () {
        this.visible = false;
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      contentBeforeUpload(file) {
        this.contentFile = [...this.contentFile, file];
        return false;
      },
      contentHandleRemove(file) {
        const index = this.contentFile.indexOf(file);
        const newFileList = this.contentFile.slice();
        newFileList.splice(index, 1);
        this.contentFile = newFileList;
      },
      avatarBeforeUpload(file) {
        this.avatarFile = []
        this.avatarFile = [...this.avatarFile, file];
        return false;
      },
      avatarHandleRemove(file) {
        const index = this.avatarFile.indexOf(file);
        const newFileList = this.avatarFile.slice();
        newFileList.splice(index, 1);
        this.avatarFile = newFileList;
      },
    }
  }
</script>