<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="订单号">
              <a-input
                placeholder="输入订单号查询"
                v-model="queryParam.orderCode"
                :maxLength="50"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="运货单号">
              <a-input
                placeholder="输入运货单号查询"
                v-model="queryParam.mailCode"
                :maxLength="50"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="收货人信息">
              <a-input
                placeholder="输入收货人电话或者姓名查询"
                v-model="queryParam.personInfo"
                :maxLength="200"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="收货人地址">
              <a-input
                placeholder="输入收货人地址查询"
                v-model="queryParam.addressInfo"
                :maxLength="200"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="下单时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                style="width: 100%"
                v-model="queryParam.pickerTimeRange"
                :format="dateFormat"
                @change="onDateChange"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
  
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
        提示: 点击列表前面的 + 号,可以展示订单子单详情
      </div>
      <div>
         <a-tabs :default-active-key="orderStatus.waitDeliver" @change="changeOrderStatus">
            <a-tab-pane :key="orderStatus.waitDeliver" :tab="'待发货 ('+orderStatusNum.waitDeliverNum+')'"></a-tab-pane>
            <a-tab-pane :key="orderStatus.delivered" :tab="'已发货 ('+orderStatusNum.deliveredNum+')'"></a-tab-pane>
            <a-tab-pane :key="orderStatus.complete" :tab="'已完成 ('+orderStatusNum.completeNum+')'"></a-tab-pane>
            <a-tab-pane :key="orderStatus.cancel" :tab="'已取消 ('+orderStatusNum.cancelNum+')'"></a-tab-pane>
            <a-tab-pane :key="orderStatus.waitPay" :tab="'待付款 ('+orderStatusNum.waitPayNum+')'"></a-tab-pane>
            <a-tab-pane :key="orderStatus.payFail" :tab="'支付失败 ('+orderStatusNum.payFailNum+')'"></a-tab-pane>
            <a-tab-pane :key="orderStatus.refund" :tab="'已退款 ('+orderStatusNum.refundNum+')'"></a-tab-pane>
          </a-tabs>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:1200}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
        
          <template slot="status" slot-scope="text, record">
            <a-tag color="#FF2D2D" v-if="record.status == orderStatus.waitPay">
              {{text}}
            </a-tag>
            <a-tag color="#FF2D2D" v-else-if="record.status == orderStatus.payFail">
              {{text}}
            </a-tag>
            <a-tag color="#f1a532" v-else-if="record.status == orderStatus.waitDeliver">
              {{text}}
            </a-tag>
            <a-tag color="#ff7575" v-else-if="record.status == orderStatus.delivered">
              {{text}}
            </a-tag>
            <a-tag color="#00A600" v-else-if="record.status == orderStatus.complete">
              {{text}}
            </a-tag>
            <a-tag color="#7A7E83" v-else-if="record.status == orderStatus.cancel">
              {{text}}
            </a-tag>
            <a-tag color="#FF2D2D" v-else-if="record.status == orderStatus.refund">
              {{text}}
            </a-tag>
          </template>

          
          <template slot="receptName" slot-scope="text, record">
             <div :title="record.receptName + '' + record.receptPhone">{{record.receptName}}  {{record.receptPhone}}</div>
             <div :title="record.receptAddress">{{record.receptAddress}}</div>
          </template>
           
          
          <span slot="action" slot-scope="text, record">
            <a v-if="record.status == orderStatus.waitDeliver" @click="sendFormSubmit(record)">发货</a>
            <a v-else-if="record.status == orderStatus.waitPay" @click="clickCancelOrder(record)">踢单</a>
            <!-- <a v-else-if="record.status == orderStatus.delivered" @click="sendFormSubmit(record)">修改快递信息</a> -->
            <a style="margin-left: 10px;" 
                 v-if="record.status != orderStatus.waitPay && record.status != orderStatus.cancel && record.status != orderStatus.refund" 
                 @click="refundOrder(record)">退款</a>
          </span>

           <!-- 展开信息 -->
          <div slot="expandedRowRender"  :defaultExpandAllRows="true" slot-scope="record" style="margin: 0">
              <a-row >
                <a-col :span="index%2==0?4:18" v-for="(item,index) in record.orderDetailsList" :key="item.id">
                  <div class="order-detail">  
                      <div class="order-detail-title">
                         <div>
                          子单号: {{item.id}}
                         </div>
                         <div>
                          {{item.createTime}}
                         </div>
                      </div>
                      <div class="order-detail-body" >
                        <div>
                          <img :src=" item.goodsImg " width="80" height="80" />
                        </div>
                        <div class="order-detail-name">
                          <div style="color:#7A7E83;width: 300px;" class="order-overflow" :title="item.goodsNameZ"> {{item.goodsNameZh}}</div>
                          <div style="color:#FF2D2D;width: 290px;" class="order-overflow" :title="item.serviceDescZh"> {{item.serviceDescZh}}</div>
                          <div class="order-detail-money"> 
                            <div>￥{{item.money}} x {{item.count}}</div>
                            <div>
                              <span class="order-detail-money-count">合计: </span>
                              <span class="order-detail-total">{{item.money * item.count}} 元 </span>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>  
                </a-col>
              </a-row>
          </div>
      </a-table>
    </div>

     <!-- 发货 -->
    <a-modal
      title="发货"
      width="600px"
      :visible="sendVisible"
      :confirmLoading="modalLoading"
      @ok="goToSendShop"
      @cancel="handleCancel"
      cancelText="关闭">
      <a-form  :form="sendForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
          <a-row>
            <a-col :span="24">
              <a-form-item label="订单号">
                 {{sendShopData.id}}
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="收货人信息">
                 {{sendShopData.receptName}}  {{sendShopData.receptPhone}} <br/>
                 {{sendShopData.receptAddress}}
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="快递公司">
                <a-select 
                  v-decorator="['mailName', { rules: [{ required: true, message: '请选择快递公司' }] }]"
                  show-search
                  style="width: 100%" 
                  option-filter-prop="children" 
                  :filter-option="filterOption"
                  placeholder="请选择快递公司">
                <a-select-option  :value="item.mailNameZh + ',' + item.mailNameEn" v-for="item in mailListData" :key="item.mailNameZh">
                  {{item.mailNameZh}}
                </a-select-option>
                    
                </a-select> 
             
              </a-form-item>
            </a-col>
            <a-col :span="24">  
              <a-form-item label="快递单号">
                 <a-input  :maxLength="100"  v-decorator="['mailCode', { rules: [{ required: true, message: '请输入运单号' }] }]" placeholder="请输入运单号"  ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
     </a-form>
    </a-modal>

   <!-- 踢单 -->
    <a-modal
       title="踢单"
       width="500px"
       :visible="cancelVisible"
       :confirm-loading="modalLoading"
       @ok="cancelOrder"
       @cancel="handleCancel"
     >
      <p>取消后无法恢复,您确定要取消订单号为: 【{{cancelData.id}}】 的订单吗?</p>
    </a-modal>

    <!-- 退款 -->
    <a-modal
       title="退款"
       :visible="refundVisible"
       :confirm-loading="modalLoading"
       @ok="toRefundMoney"
       @cancel="handleCancel"
       :width="600"
     >
       <div>
         <a-form  :form="refundForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="商品信息">
                   {{refundOrderParam.shopInfo}}   <span style="color:red" v-if="refundOrderParam.specsInfo"> ({{refundOrderParam.specsInfo}}) </span>
                </a-form-item>
                <a-form-item label="商品金额">
                  {{refundOrderParam.money}} 元 ( 共{{refundOrderParam.count}}件 )
                </a-form-item>
                <a-row>
                  <a-col :span="24">
                    <a-form-item label="退款金额">
                      <a-input-number :max="refundOrderParam.money" :min="0.01" :precision="2"
                          v-decorator="['refundMoney', { rules: [{ required: true, message: '请输入退款金额!' }]}]"
                            placeholder="请输入退款金额" style="width: 100%" />
                      <a-icon style="margin-right:10px;color:#EA7500" type="info-circle" />本单仅可操作一次退款,退款后无法再次退款 
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-form-item label="操作密码">
                  <a-input-password 
                      v-decorator="['refundPwd', { rules: [{ required: true, message: '请输入操作密码!' }] }]"
                        placeholder="请输入操作密码" style="width: 100%" />
                  </a-form-item>
                <a-form-item label="备注">
                  <a-input  
                      v-decorator="['refuseReason', { rules: [{ required: false, message: '请输入备注!' }] }]"
                        placeholder="请输入备注" style="width: 100%" />
                  </a-form-item>
              </a-col>
            </a-row>
         </a-form>
       </div>
    </a-modal>

  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import Vue from 'vue'
  import { USER_INFO,ACCESS_TOKEN } from '@/store/mutation-types'
  import { getAction, postAction,uploadAction } from '@/api/manage'
  import moment from 'moment';
 

  export default {
    name: 'OrderList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
     
    },
    data () {
      return {
        description: '订单管理页面',
        switchLoading:false,
        refundVisible:false,
        loading:false,
        orderStatus : {
					waitPay : 'wait_pay',
					waitDeliver : 'wait_deliver',
					delivered : 'delivered',
					complete : 'complete',
					cancel : 'cancel',
					payFail : 'pay_fail',
					refund: 'refund'
				},
        sendVisible:false,
        modalLoading:false,
        refundForm:this.$form.createForm(this, { name: 'refundForm' }),
        cancelVisible:false,
        sendForm:this.$form.createForm(this, { name: 'coordinated' }),
        dateFormat:'YYYY-MM-DD',
        // 表头
        columns: [
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:160,
            scopedSlots: { customRender: 'action' }
          },
          {
            title:'订单号',
            align:"center",
            dataIndex: 'id',
            ellipsis: true,
            width:200,
          },
          {
            title:'应付总金额（元）',
            align:"center",
            dataIndex: 'totalMoney',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 元';
            }
          },
          {
            title:'实付金额（元）',
            align:"center",
            dataIndex: 'realMoney',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 元';
            }
          },
          {
            title:'优惠金额（元）',
            align:"center",
            dataIndex: 'couponMoney',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 元';
            }
          },
          {
            title:'总件数',
            align:"center",
            dataIndex: 'totalNum',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 件';
            }
          },
          {
            title:'订单状态',
            align:"center",
            dataIndex: 'statusZh',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'status' },
          }, 
          {
            title:'收货人信息',
            align:"center",
            ellipsis: true,
            width:250,
            dataIndex: 'receptName',
            scopedSlots: { customRender: 'receptName' },
          },
          {
            title:'订单备注',
            align:"center",
            dataIndex: 'remarks',
            ellipsis: true,
            width:250,
          },
          {
            title:'下单时间',
            align:"center",
            dataIndex: 'createTime',
            ellipsis: true,
            width:170,
          },
      
         
          {
            title:'支付类型',
            align:"center",
            dataIndex: 'payTypeZh',
            ellipsis: true,
            width:150,
          },
          {
            title:'支付时间',
            align:"center",
            dataIndex: 'payTime',
            ellipsis: true,
            width:150,
          },
          {
            title:'支付失败原因',
            align:"center",
            dataIndex: 'payFailReasonZh',
            ellipsis: true,
            width:250,
          },
          {
            title:'发货时间',
            align:"center",
            dataIndex: 'sendTime',
            ellipsis: true,
            width:170,
          },
          {
            title:'快递公司名称',
            align:"center",
            dataIndex: 'mailNameZh',
            ellipsis: true,
            width:200,
          },
          {
            title:'运单号',
            align:"center",
            dataIndex: 'mailCode',
            ellipsis: true,
            width:200,
          },
          
          {
            title:'用户确认收货时间',
            align:"center",
            dataIndex: 'receiveTime',
            ellipsis: true,
            width:150,
          },
        
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        url: {
          list: "/shop/web/order/getShopOrderList",
          cancelOrder:"/shop/web/order/cancelOrder",
          sendShop:"/shop/web/order/sendShop",
          countWebOrderStatusTotal:"/shop/web/order/countWebOrderStatusTotal",
          updateMailInfo:"/shop/web/order/updateMailInfo",
          refundMoney:"/shop/web/shopRefund/tenantRefundMoney",
        },
        // 中国快递公司数据
        mailListData:[
         {
              mailNameZh:'中通快递',
              mailNameEn:'ZTO',
          },
          {
              mailNameZh:'顺丰速运',
              mailNameEn:'SF',
          },
          {
              mailNameZh:'韵达速递',
              mailNameEn:'YD',
          },
          {
              mailNameZh:'申通快递',
              mailNameEn:'STO',
          },
          {
              mailNameZh:'圆通速递',
              mailNameEn:'YTO',
          },{
              mailNameZh:'京东快递',
              mailNameEn:'JD',
          },{
              mailNameZh:'测试快递',
              mailNameEn:'JD',
          }
        ],
        dictOptions:{},
        superFieldList:[],
        shopGroupList:[],
        accessToken:'',
        cancelData:{},
        refundOrderParam:{},
        // 发货信息
        sendShopData:{},
        orderStatusNum:{},
        // 是否更新发货信息
        isUpdateMailInfo:false,

      }
    },
    created() {
      const accessToken  = Vue.ls.get(ACCESS_TOKEN)
      this.accessToken = accessToken
      this.queryParam.status = this.orderStatus.waitDeliver
      this.countWebOrderStatusTotal();
     
    },
    computed: {
  
    },
    methods: {
      moment,
      // 切换订单状态
      changeOrderStatus(e){
          this.queryParam.status = e
          this.loadData();
      },
      // 统计各个订单的状态
      countWebOrderStatusTotal(){
        let that = this;
        that.loading = true;
        getAction(this.url.countWebOrderStatusTotal,{}).then((res)=>{
            if(res.code == 200){
              that.orderStatusNum = res.data
            }else{
              that.$message.error(res.message);
            }
        }).finally(() => {
            that.loading = false;
        })
      },
      searchReset(){
        this.queryParam.status = this.orderStatus.waitPay
        this.loadData();
      },
      // 发货
      sendFormSubmit(record){
         this.sendShopData = record
         this.sendForm.resetFields();
         let formParam = {};
         this.isUpdateMailInfo = false
         if(record.mailCode){
            formParam.mailCode = record.mailCode;
            this.isUpdateMailInfo = true
         }
         if(record.mailNameZh){
            this.isUpdateMailInfo = true
            formParam.mailName = record.mailNameZh + ',' + record.mailNameEn;
         }
         if(this.isUpdateMailInfo){
           this.$nextTick(() => {
              this.sendForm.setFieldsValue(formParam)
           })
         }
         this.sendVisible = true;
      },
      refundOrder(record){
        console.log(record)
          let param = {
            orderId:record.id,
            orderDetailsId: record.orderDetailsList[0].id,
            shopInfo:record.orderDetailsList[0].goodsNameZh,
            specsInfo:record.orderDetailsList[0].specsNameZh,
            count:record.orderDetailsList[0].count,
            money:record.orderDetailsList[0].money
          }
          this.refundOrderParam = param
          this.refundForm.resetFields();
          this.modalLoading = false;
          this.refundVisible = true;
      },
      toRefundMoney(){
         let that = this
         this.refundForm.validateFields((err, values) => {
          if (!err) {
              this.modalLoading = true
              values.orderId = that.refundOrderParam.orderId
              values.orderDetailsId = that.refundOrderParam.orderDetailsId
              postAction(this.url.refundMoney,values).then((res)=>{
                if(res.code == 200){
                  that.$message.success("操作成功");
                  that.modalFormOk();
                  that.handleCancel();
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                this.modalLoading = false
              })
          }})
      },
      goToSendShop(){
        let that = this;
        this.sendForm.validateFields((err, values) => {
          if (!err) {
            let mailName = values.mailName
            let param = {
              mailCode : values.mailCode,
              orderId : that.sendShopData.id
            }
            if(mailName){
               param.mailNameZh = mailName.split(',')[0],
               param.mailNameEn = mailName.split(',')[1]
            }
            let httpUrl = '';
            let isLoadTotalNum  = false
            // 如果是待发货 则执行发货
            if(that.sendShopData.status == this.orderStatus.waitDeliver){
                httpUrl = this.url.sendShop;
                isLoadTotalNum  = true;
            }else{
              // 否则执行修改快递信息
              httpUrl = this.url.updateMailInfo;
            }

            that.modalLoading = true
            postAction(httpUrl,param).then((res)=>{
              if(res.code == 200){
                that.$message.success("操作成功");
                that.loadData();
                if(isLoadTotalNum){
                  that.countWebOrderStatusTotal()
                }
                that.handleCancel();
              }else{
                that.$message.error(res.message);
              }
            }).finally(() => {
              that.modalLoading = false;
            })
          }
         })
      },
      // 踢单
      clickCancelOrder(record){
         this.cancelData = record
         this.cancelVisible = true
      },
      cancelOrder(){
        let that = this
        let param = {
           orderId : this.cancelData.id
        }
        this.modalLoading = true
        postAction(this.url.cancelOrder,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("取消订单成功");
            that.loadData();
            that.handleCancel();
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.modalLoading = false;
        })
      },
      // 刷新数据
      refeshData(res){
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      handleCancel(){
        this.cancelVisible = false;
        this.sendVisible = false;
        this.refundVisible = false;
      },
      onDateChange: function (value, dateString) {
  
        this.queryParam.startTime=dateString[0];
        this.queryParam.endTime=dateString[1];
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
   .order-detail{
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      box-shadow: 5px 5px 2px #888888;
      /* height: 200px; */
      width: 400px;
      font-size: 10px;
      padding-top: 10px;
      padding-left: 10px;
      margin-top: 20px;
   }
   .order-detail-title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 10px;
      font-size: 10px;
   }
  .order-detail-body{
    display: flex;
    flex-direction: row;
    border-top: #c4c6c9 solid 1px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 10px;
  }
  .order-detail-name{
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     margin-left: 10px;
     margin-right: 10px;
  }
  .order-detail-money{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color:#000;
    font-size: 17px;
    font-weight: 600;
  }
  .order-detail-money-count{
     font-size: 10px;
     color: #7A7E83;
     font-weight: 500;
     margin-left: 50px;
  }
  .order-detail-total{
     font-size: 17px;
     color: #FF2D2D;
     font-weight: 600;
     margin-left: 10px;
     margin-right: 10px;
  }
  .order-overflow{
      overflow: hidden;/*超出部分隐藏*/
      text-overflow:ellipsis;/* 超出部分显示省略号 */
      white-space: nowrap;/*规定段落中的文本不进行换行 */
  }
</style>