<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="优惠券金额(整数)">
                  <a-input-number :max="99999" :min="0" :precision="0"
                           v-decorator="['couponMoney', { rules: [{ required: true, message: '请输入优惠券金额,必须是整数!' }] }]"
                           placeholder="请输入优惠券金额,必须是整数" style="width: 100%" />        
                    <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
                        本设置都会覆盖<span style="color: red;">商品原来的优惠券(本优惠券优先级最高)</span> 请谨慎设置。设置后链接保存好，本平台无法再次查询链接  
                  </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="链接失效时间(分钟)">
                  <a-input-number :max="43200" :min="1" :precision="0"
                      v-decorator="['effectCouponTime', { rules: [{ required: true, message: '请输入链接失效时间,必须是整数!' }] }]"
                      placeholder="请输入链接失效时间,必须是整数" style="width: 100%" />        
                  <br/>
                  <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
                        超出设置时间后,链接会自动失效。<span style="color: red;">单位为分钟,最高可设置43200分钟,即一个月</span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
          <a-modal
              title="用户专享优惠链接"
              :visible="resultVisible"
              :maskClosable="false"
              :keyboard="false"
              :footer="null"
              @cancel="handleCancel"
             >
             <p>{{ resultText }}</p>
              <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
                  请立即保存链接，点击关闭后链接消失<span style="color: red;">本平台无法再次查询链接  </span>
          </a-modal>
    </a-modal>  
    
</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/commonUtil'
  // 设置专享优惠链接
  export default {
    name: 'ShopUserVipCouponModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        resultVisible:false,
        openLanguage:false,
        confirmLoading: false,
        resultText:'',
        record:{},
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          add: "",
          edit: "",
          genUserShopCoupon:'/shop/web/shop/genUserShopCoupon'
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      show (record) {
        console.log(record)
        this.form.resetFields();
        this.record = record;
        this.resultText = '';
        this.title = "设置专享优惠链接: " + record.goodsNameZh
        this.visible = true;
      },
      submitForm () {
        let that = this;
        this.form.validateFields((err, values) => {
          if (!err) {
                let param = {
                  goodsId : that.record.id,
                  couponMoney: values.couponMoney,
                  effectCouponTime:  values.effectCouponTime * 60
                }
              that.confirmLoading = true
              postAction(this.url.genUserShopCoupon,param).then((res)=>{
                if(res.code == 200){
                  that.resultText = res.data;
                  that.resultVisible = true;
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.confirmLoading = false;
              })
          }
         })
      },
      handleCancel () {
        this.visible = false;
        this.resultVisible = false;
      }
    }
  }
</script>