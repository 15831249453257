<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="商品名称">
              <a-input
                :allowClear="true" 
                :maxLength="240" 
                placeholder="请输入商品名称"
                v-model="queryParam.goodsNameZh"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="是否发布">
              <a-select
                  placeholder="请选择是否发布"
                  style="width: 100%"
                   v-model="queryParam.status"
                  :allowClear="true" 
                >
                  <a-select-option  value="1">
                     未发布
                  </a-select-option>
                  <a-select-option  value="0">
                     已发布
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="评论分数">
              <a-select
                  placeholder="请选择评论分数"
                  style="width: 100%"
                   v-model="queryParam.rate"
                  :allowClear="true" 
                >
                  <a-select-option  value="0">
                     0分
                  </a-select-option>
                  <a-select-option  value="1">
                     1分
                  </a-select-option>
                  <a-select-option  value="2">
                     2分
                  </a-select-option>
                  <a-select-option  value="3">
                     3分
                  </a-select-option>
                  <a-select-option  value="4">
                     4分
                  </a-select-option>
                  <a-select-option  value="5">
                     5分
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="searchReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="addEvaluate()" type="primary" icon="plus">新增评论</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
           注意: 1.用户自主的评论<span style="color:red">无法删除</span> 
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:1200}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
         :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">


        <template slot="deleteStatus" slot-scope="text, record">
          <template  v-if="record.deleteStatus == 0">
            <a-switch checked-children="已发布"  :checked="true" :loading="switchLoading" @click="changeStatus(record, 1)" />
          </template>
          <template  v-else>
            <a-switch un-checked-children="未发布" :checked="false" :loading="switchLoading" @click="changeStatus(record, 0)" />
          </template>
        </template>

        <template slot="headImg" slot-scope="text, record">
          <a-avatar
              slot="avatar"
              :src="record.headImg"
              alt="Han Solo"
              @click="showImg(record.headImg)"
            />
        </template>
        <template slot="imgList" slot-scope="text, record">
          <template  v-for="(item , i ) in record.imgList" >
            <img
             :key="i"
             style="width: 60px;height: 60px;margin-left: 10px;"
             :src="item"
             @click="showImg(item)"
           />
          </template>
        
        </template>

        <span slot="action" slot-scope="text, record">
            <a-popconfirm title="删除后无法恢复,确定删除吗?" v-if="record.customerUserId == '0'" @confirm="() => handleDelete(record.id)">
              <a>删除</a>
            </a-popconfirm>
        </span>

      </a-table>
    </div>
    <add-evaluate-modal ref="addEvaluateModal" @ok="modalFormOk" />
    <img-show-modal ref="imgShowModal" />
  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import AddEvaluateModal from './modules/AddEvaluateModal.vue'
  import ImgShowModal from '../../../../components/common/ImgShowModal.vue'
  import { postAction, getAction,uploadAction } from '@/api/manage'
  export default {
    name: 'EvaluateList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
      AddEvaluateModal,
      ImgShowModal
    },
    data () {
      return {
        description: 'RotationList管理页面',
        switchLoading: false,
        // 表头
        columns: [
          {
            title:'商品名称',
            align:"center",
            dataIndex: 'goodsNameZh',
            ellipsis: true,
            width:300,
          },
          {
            title:'状态',
            align:"center",
            dataIndex: 'deleteStatus',
            ellipsis: true,
            scopedSlots: { customRender: 'deleteStatus' },
            width:100,
          },
          {
            title:'用户头像',
            align:"center",
            dataIndex: 'headImg',
            scopedSlots: { customRender: 'headImg' },
            ellipsis: true,
            width:100,
          },
          {
            title:'用户昵称',
            align:"center",
            dataIndex: 'nickName',
            ellipsis: true,
            width:200,
          },
          {
            title:'评论内容',
            align:"center",
            dataIndex: 'content',
            ellipsis: true,
            width:300,
          },
          
          {
            title:'评价图片',
            align:"center",
            dataIndex: 'content11',
            scopedSlots: { customRender: 'imgList' },
            ellipsis: true,
            width:330,
          },
          {
            title:'评分(5分满)',
            align:"center",
            dataIndex: 'rate',
            ellipsis: true,
            width:100,
          },
       
          {
            title:'评论时间',
            align:"center",
            dataIndex: 'createTime',
            ellipsis: true,
            width:300,
          },
          {
            title:'订单号',
            align:"center",
            dataIndex: 'orderId',
            ellipsis: true,
            width:100,
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/shop/web/shop/getShopEvaluateList",
          getAllShopList:"/shop/web/shop/getAllShopList",
          changeStatus:"/shop/web/shop/changeStatus",
          delEvaluate:"/shop/web/shop/delEvaluate",
        },
        allShopList: null
      }
    },
    created() {
    },
    computed: {
    
    },
    methods: {
       addEvaluate(){
        let that = this;
        if(that.allShopList != null){
          this.$refs.addEvaluateModal.add(that.allShopList);
          return;
        }
        getAction(this.url.getAllShopList,{}).then((res)=>{
          if(res.code == 200){
            that.allShopList = res.data;
            that.$refs.addEvaluateModal.add(res.data);
          }else{
            that.allShopList = null;
          }
        })
       },
       updateEvaluate(record){
          this.$refs.addEvaluateModal.update(record);
       },
       modalFormOk(){
        this.loadData();
       },
       showImg(url){
        let img = [url];
        let param = {
          url:img,
        }
        this.$refs.imgShowModal.show(param);
      },
      changeStatus(record,status){
        let that = this
        this.switchLoading = true;
        let param = {
           id: record.id,
           status : status
        }
        postAction(this.url.changeStatus,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("修改成功");
            record.deleteStatus = status;
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.switchLoading = false;
        })
      },
      // 删除商品
      handleDelete(id){
        let that = this
        let param = {
          id:id
        }
        this.loading = true
        postAction(this.url.delEvaluate,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("删除成功");
            that.modalFormOk();
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.loading = false;
        })
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>