<template>
  <div class="index-page">
    欢迎您使用电商工具平台
  </div>
</template>

<script>

  export default {
    name: "Analysis",
    data() {
      return {
        indexStyle:1
      }
    },
    created() {

    },
    methods: {

    }
  }

  
</script>
<style lang="less" scoped>
  .index-page{
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
    //  background-color: red;
     width: 100%;
     margin-top: 20%;
     font-size: 30px;
  }
</style>